import './ImageUpload.scss';
import React, { useCallback, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { uploadImage } from "../../services";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { GameImage } from '../../models';

export type ImageUploadProps = {
	onUploadFinish: (data: GameImage[]) => void;
}

const ImageUpload: React.FC<ImageUploadProps> = ({ onUploadFinish }) => {
	const [isUploading, setIsUploading] = useState<boolean>(false);

	const onDrop = useCallback((files) => {
		if (!files.length) {
			return;
		}

		setIsUploading(true);
		uploadImage(files).then(([result, error]) => {
			setIsUploading(false);
			if (error) {
				console.error(error);
				return;
			}

			if (result) {
				onUploadFinish(result);
			}
		});
	}, []);

	const config = {
		onDrop,
		accept: { 'image/jpeg': [] },
		validator: (file) => {
			if (file.size < 1000000) {
				return null;
			}

			return {
				code: "size-too-large",
				message: "File size is larger than 1MB."
			};
		}
	};
	const { acceptedFiles, getRootProps, getInputProps, fileRejections } = useDropzone(config);

	return (
		<div className="image-upload">
			<div {...getRootProps()}>
				<input {...getInputProps()}/>
				<div className="drop-zone">
					{/*<p className="icon">*/}
					{/*	{*/}
					{/*		isUploading ?*/}
					{/*			<FontAwesomeIcon icon="spinner" className="fa-spin" size="2x" /> :*/}
					{/*			<FontAwesomeIcon icon="upload" size="2x" />*/}
					{/*	}*/}
					{/*</p>*/}
					{/*<p className="description">Drag 'n' drop some files here, or click to select (size under 1.0 MB)</p>*/}
					{/*{ acceptedFiles && <FileList files={acceptedFiles} />}*/}
					{/*{ */}
					{/*	fileRejections && */}
					{/*		<FileList files={fileRejections.map(({ file, errors }) => {*/}
					{/*			return { name: file.name, size: file.size, errors };*/}
					{/*		})} />*/}
					{/*}*/}
				</div>
			</div>
		</div >
);
}

type FileListProps = {
	files: any[];
}

const FileList:React.FC<FileListProps> = ({ files }) => {
	return (
		<ul className="list-unstyled">
			{
				files.map(({ name, size, errors}, i) => (
					<li key={name ?? i}>{name}{errors && errors.length > 0 && <span className="ps-1 text-danger">{errors[0].code}: {(size/1024/1024).toFixed(2)}MB</span>}</li>))
			}
		</ul>
	);
};

ImageUpload.displayName = 'ImageUpload';

export default React.memo(ImageUpload);